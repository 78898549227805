import { computed } from '@nuxtjs/composition-api';
import {
  Logger,
  sharedRef,
  useVSFContext,
  VSF_CURRENCY_COOKIE
} from '@vue-storefront/core';
import { useCurrentUser } from '../useCurrentUser';
import { useBloomreachEvents } from '../useBloomreachEvents';
import { createBloomreachCartUpdateEventDetails } from '../shared';

const CART_COOKIE = 'n_shopping_cart';
const CART_ITEMS_COUNT_COOKIE = 'n_cart_items_count';

export const useKECart = (): {
  cartItems: any;
  filteredCartItems: any;
  cartItemsCount: any;
  loading: any;
  error: any;
  totalPrice: any;
  isOpen: any;
  getCart: any;
  updateCart: any;
  addToCart: any;
  setIsCartOpen: any;
  setCookieCartCount: any;
  setCartCount: any;
  clearCartCookies: any;
} => {
  const context = useVSFContext();

  const shopping_cart_id = sharedRef(null, 'useKECart-shopping_cart_id');
  const cartItems = sharedRef(null, 'useKECart-cartItems');
  const filteredCartItems = computed(() => {
    return cartItems.value?.filter((item) => !item.is_shipping_costs_item);
  });
  const cartItemsCount = sharedRef(0, 'useKECart-cartItemsCount');
  const totalPrice = sharedRef(0, 'useKECart-totalPrice');
  const isOpen = sharedRef(false, 'useKECart-isOpen');
  const loading = sharedRef(
    {
      getCart: false,
      updateCart: false
    },
    'useKECart-loading'
  );
  const error = sharedRef(
    {
      getCart: null,
      updateCart: null
    },
    'useKECart-error'
  );

  const { currentUser, getUserToken } = useCurrentUser();
  const { registerEvent } = useBloomreachEvents();

  const setCartCount = (count: number) => {
    context.$cookies.set(CART_ITEMS_COUNT_COOKIE, count);
    cartItemsCount.value = count;
  };

  const setCookieCartCount = () => {
    const cartCount = context.$cookies.get(CART_ITEMS_COUNT_COOKIE) || 0;
    setCartCount(cartCount);
  };

  const setCartCookie = (cart_guid) => {
    context.$cookies.set(CART_COOKIE, cart_guid, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 * 6
    });
  };

  const getCartItem = ({
    spliId,
    combidealIds
  }: {
    spliId: number;
    combidealIds: number[] | null;
  }) => {
    const spliItems =
      cartItems.value?.filter((item: any) => item.spli_id === spliId) || [];
    if (!combidealIds || combidealIds.length === 0) {
      const spliItem = spliItems.find(
        (item: any) => item.shoppingcart_combination_items?.length === 0
      );
      return spliItem ?? null;
    } else {
      const spliItem = spliItems.find((item: any) => {
        if (
          !item.shoppingcart_combination_items ||
          item.shoppingcart_combination_items.length === 0
        )
          return false;
        const combidealItems = item.shoppingcart_combination_items || [];
        const combidealItemIds = combidealItems.map(
          (item: any) => item.combideal_id
        );
        if (
          !combidealItemIds.every((id) => combidealIds.includes(id)) ||
          !combidealIds.every((id) => combidealItemIds.includes(id))
        ) {
          return false;
        }
        return true;
      });

      return spliItem ?? null;
    }
  };

  const setIsCartOpen = (value: boolean) => {
    isOpen.value = value;
  };

  const getCart = async () => {
    const cartId = context.$cookies.get(CART_COOKIE) || null;
    const userToken = getUserToken();
    Logger.debug('useKECart getCart custom componsable', cartId);
    if (!cartId && !userToken) {
      cartItems.value = [];
      totalPrice.value = 0;
      setCartCount(0);
      return;
    }
    try {
      loading.value.getCart = true;
      const response = await context.$novulo.api.getKECart({
        cartId,
        userToken
      });
      if (!response || response.length === 0) {
        cartItems.value = [];
        totalPrice.value = 0;
        setCartCount(0);
      } else {
        const data = response[0];
        shopping_cart_id.value = data.id;
        cartItems.value = data.shoppingcart_items;
        totalPrice.value = data.total_price_after_discount;
        const itemsCount = filteredCartItems.value.reduce(
          (acc, item) => acc + item.quantity,
          0
        );

        setCartCount(itemsCount);

        if (!cartId) setCartCookie(data.shoppingcart_guid);
      }
    } catch (err) {
      error.value.getCart = err;
      Logger.error('useKECart/getCart', err);
    } finally {
      loading.value.getCart = false;
    }
  };

  const registerBloomreachEvent = ({
    cartId,
    cartItem,
    oldQuantity,
    newQuantity,
    currency,
    browserDetails
  }) => {
    if (!cartItem) return;
    registerEvent(
      'cart_update',
      createBloomreachCartUpdateEventDetails({
        cartId,
        cartItems: cartItems.value,
        cartItem,
        oldQuantity,
        newQuantity,
        currency,
        browserDetails
      })
    );
  };

  const updateCart = async ({
    spliId,
    combidealIds = null,
    oldQuantity,
    newQuantity,
    openCartAfterUpdate = true,
    currency,
    browserDetails
  }: {
    spliId: number;
    combidealIds: number[] | null;
    oldQuantity: number;
    newQuantity: number;
    openCartAfterUpdate?: boolean;
    currency: string;
    browserDetails: object;
  }) => {
    let cartId = context.$cookies.get(CART_COOKIE) || null;
    Logger.debug(
      'useKECart updateCart custom componsable',
      spliId,
      newQuantity,
      context
    );
    try {
      loading.value.updateCart = true;
      if (!cartItems.value) {
        await getCart();
      }
      let cartItem = getCartItem({ spliId, combidealIds });
      const { error, success, response } =
        await context.$novulo.api.updateKECart({
          cartId,
          shopping_cart_id: shopping_cart_id.value,
          spliId,
          newQuantity,
          userId: currentUser.value?.id,
          userToken: getUserToken(),
          combiItemsIds: cartItem?.id ? null : combidealIds,
          cartItemId: cartItem?.id ?? null
        });
      if (error || !success || !response || !response.cart_guid) {
        context.$cookies.remove(CART_COOKIE);
        throw new Error('Error updating cart');
      } else {
        setCartCookie(response.cart_guid);
        cartId = response.cart_guid;
      }
      // update cartItems
      await getCart();
      if (openCartAfterUpdate) setIsCartOpen(true);
      if (!cartItem) cartItem = getCartItem({ spliId, combidealIds });
      registerBloomreachEvent({
        cartId,
        cartItem,
        oldQuantity,
        newQuantity,
        currency,
        browserDetails
      });
    } catch (err) {
      error.value.update = err;
      Logger.error('useKECart/update', err);
    } finally {
      loading.value.updateCart = false;
    }
  };
  const addToCart = async ({
    spliId,
    combidealIds,
    quantity,
    category,
    brand,
    name,
    price,
    list,
    sku,
    currency,
    browserDetails
  }: {
    spliId: number;
    combidealIds: number[] | null;
    quantity: number;
    category: string | null;
    brand: string | null;
    name: string | null;
    price: number | null;
    list: string | null;
    sku: string;
    currency: string;
    browserDetails: object;
  }) => {
    if (!cartItems.value) {
      await getCart();
    }
    const currentQuantity =
      getCartItem({ spliId, combidealIds })?.quantity ?? 0;
    // If no list, dont push event addToCart to GTM
    if (window && (window as any).dataLayer && list)
      (window as any).dataLayer.push({
        ecommerce: {
          currencyCode: context.$cookies.get(VSF_CURRENCY_COOKIE),
          add: {
            actionField: {
              list: list
            },
            products: [
              {
                name: name,
                id: sku,
                price: price,
                brand: brand,
                category: category,
                quantity: quantity
              }
            ]
          }
        },
        event: 'addToCart'
      });
    await updateCart({
      spliId,
      combidealIds,
      oldQuantity: currentQuantity,
      newQuantity: currentQuantity + quantity,
      currency,
      browserDetails
    });
  };

  const clearCartCookies = () => {
    context.$cookies.remove(CART_COOKIE);
    context.$cookies.remove(CART_ITEMS_COUNT_COOKIE);
  };

  return {
    cartItems: computed({
      get: () => cartItems.value,
      set: (data) => (cartItems.value = data)
    }),
    filteredCartItems,
    cartItemsCount: computed(() => cartItemsCount.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    totalPrice: computed(() => totalPrice.value),
    isOpen: computed(() => isOpen.value),
    setIsCartOpen,
    getCart,
    updateCart,
    addToCart,
    setCookieCartCount,
    setCartCount,
    clearCartCookies
  };
};
