import { computed, useContext } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
export const useFooter = () => {
  const context = useContext();
  const vsfContext = useVSFContext();
  const footerResult = sharedRef(null, 'useFooter');
  const loading = sharedRef(false, 'useFooter-loading');
  const error = sharedRef(
    {
      getPDP: null
    },
    'useFooter-error'
  );

  const getFooter = async () => {
    try {
      loading.value = true;
      footerResult.value = await vsfContext.$novulo.api.getFooter();
    } catch (err) {
      error.value.getFooter = err;
      Logger.error('useFooter', err);
    } finally {
      loading.value = false;
    }
  };

  const subscribeToNewsletter = (params) => {
    const domainConfig = vsfContext.$novulo.config.state.getDomainConfig();
    const language = vsfContext.$novulo.config.state.getLanguage();
    const bloomreachToken = context.$config.bloomreachTrackingToken;
    return vsfContext.$novulo.api.subscribeToNewsletter({
      ...params,
      bloomreachToken,
      websiteLabel: domainConfig.websiteLabel,
      websiteName: domainConfig.websiteName,
      language,
      country: domainConfig.websiteCountry
    });
  };

  return {
    getFooter,
    subscribeToNewsletter,
    footerResult: computed(() => footerResult.value),
    footerLoading: computed(() => loading.value),
    footerError: computed(() => error.value)
  };
};
